import {httpGet, httpPost} from '@/plugins/request'


/**
 * 查询订阅
 * @returns {*}
 */
export function apiGetSubscribe(query = {})
{
    return httpGet('/user/subscribe', query)
}


/**
 * 启用/停用订阅
 * @returns {*}
 */
export function apiStoreSubscribe(query = {})
{
    return httpPost('/user/subscribe', query)
}

/**
 * 获取核销记录
 *
 * @param query
 * @returns {*}
 */
export function apiGetMyVerify(query ={})
{
    return httpGet('/merchant/task/verify', query)
}


/**
 * 获取核销记录详情
 *
 * @param id
 * @param query
 * @returns {*}
 */
export function apiGetMyVerifyShow(id, query ={})
{
    return httpGet(`/merchant/task/verify/${id}`, query)
}
