<template>
<div>
      <van-cell-group title="核销记录">
        <van-cell
            :key="key"
            v-for="(item, key) in data"
            size="large"
            is-link
            :to="{name: 'task.record.show', params: {rid: item.id}, query: {id, p: password}}"
            :label="item.name">
          <template #title>
            <div class="van-ellipsis">{{ item.task_desc }}</div>
          </template>
          <template #label>
            {{item.sale_code}}
          </template>
          <template #value>
            <div>
              <span style="color:red;">￥{{item.sale_confirm_price}}</span>
            </div>
            <div>
              <small>{{ item.created_at }}</small>
            </div>
          </template>
        </van-cell>
      </van-cell-group>

  <br>
  <van-pagination
      v-model="search.page"
      :total-items="page.total"
      :items-per-page="search.per_page"
      @change="getVerify()"
      mode="simple" />
</div>
</template>

<script>
  import {apiGetMyVerify} from '@/api/myApi'
  export default {
    created () {
      this.getVerify()
      this.id = this.$route.query.id
      this.password =this.$route.query.p
    },
    data () {
      return {
        id:'',
        password: '',
        data: [],
        search:{
          page: 1,
          per_page: 15,
        },
        page:{
          total: 0,
        }
      }
    },
    methods: {
      getVerify () {
        apiGetMyVerify(this.search).then(res =>{
          this.data = res.data
          this.page = res.meta
        })
      }
    }
  }
</script>
