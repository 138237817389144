<template>
<div>
  <router-view/>
<div style="margin-top:70px;">
  &nbsp;
</div>
  <van-tabbar v-model="active">
    <van-tabbar-item icon="home-o" :to="{name: 'task.sale', params: {id, password}}">核销</van-tabbar-item>
    <van-tabbar-item icon="search" :to="{name: 'task.record', query: {id, p: password}}">记录</van-tabbar-item>
  </van-tabbar>
</div>
</template>


<script>
import {checkLogin} from '@/plugins/auth'
export default {
  created () {
    this.isLogin()
    this.id = this.$route.params.id ? this.$route.params.id : this.$route.query.id
    this.password = this.$route.params.password ? this.$route.params.password : this.$route.query.p
  },
  data() {
    return {
      active: '',
      id: '',
      password: '',
    }
  },
  methods:{
    isLogin() {
      if (!checkLogin(false)) {
        this.$router.push({
          name: "login"
        })
      }
    }
  }
}
</script>
