export default [
    {
        path: '/',
        name: 'base',
        component: () => import('@/components/layout/main.vue'),
        redirect: '/home',
        children: [
            // 核销
            {
                path: '/task/sale/:id/:password',
                name: 'task.sale',
                component: () =>import('@/components/task/sale.vue')
            },
            // 核销
            {
                path: '/task/manage/:id/:password',
                name: 'task.send',
                component: () =>import('@/components/task/send.vue')
            },
            // 核销
            {
                path: '/task/record',
                name: 'task.record',
                component: () =>import('@/components/task/record.vue')
            },
            // 核销
            {
                path: '/task/record/:rid',
                name: 'task.record.show',
                component: () =>import('@/components/task/record-show.vue')
            },
        ]
    },
    // 首页
    {
        path: '/home',
        name: 'index',
        component: () =>import('@/components/index.vue')
    },
    {
        path: '/auth/login',
        name: 'login',
        component: () =>import('@/components/auth/login.vue'),
    },
    {
        path: '/auth/logout',
        name: 'logout',
        component: () =>import('@/components/auth/logout.vue'),
    },
    {
        path: '/wechat-error',
        name: 'wechat.error',
        component: () =>import('@/components/auth/wechat-error.vue'),
    },
]
