<template>
<div>
  <!-- 通用错误 -->
  <van-empty image="error" v-if="data.length === 0" description="加载中" />

  <van-cell-group title="详情" v-else>
    <van-cell title="团单" :value="data.task_desc" />
    <van-cell title="核销码" :value="data.sale_code" />
    <van-cell title="序号" :value="data.sale_sort" />
    <van-cell title="购买提交金额" :value="data.sale_price" />
    <van-cell title="确认金额" :value="data.sale_confirm_price" />
    <van-cell title="操作时间" :value="data.created_at" />
    <van-cell title="状态" :value="data.status_desc" />
    <van-cell title="团购图">
      <template #value>
        <van-image :src="data.sale_image" width="100" @click="reviewImg()"></van-image>
      </template>
    </van-cell>
  </van-cell-group>
</div>
</template>

<script>
import {apiGetMyVerifyShow} from '@/api/myApi'
import {showImagePreview} from 'vant'
  export default {
    created() {
      this.rid = this.$route.params.rid
      if (!parseInt(this.rid)) {
        this.$router.replace({
          name: 'my.user.settle'
        })
        return
      }

      this.getVerify()
    },
    data () {
      return {
        rid: 0,
        data: {},
      }
    },
    methods:{
      getVerify () {
        apiGetMyVerifyShow(this.rid).then(res =>{
          this.data = res
        })
      },
      reviewImg () {
        showImagePreview([this.data.sale_image])
      }
    }
  }
</script>
